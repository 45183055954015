<template>
  <div>
    <van-nav-bar
      title="个人信息"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form>
      <van-field
        v-model.trim="name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
      />
      <van-field
        v-model.trim="idcard"
        name="身份证号码"
        label="身份证号码"
        placeholder="身份证号码"
      />
      <van-field
        readonly
        v-model="idcard_check_status"
        name="核验状态"
        label="核验状态"
      />
      <div class="my_tips">
      <b>用户须知：</b><br/>
      1.身份证信息仅用于提现转帐和个人所得税的申报<br/>
      2.姓名必须和提现银行开户名保持一致<br/>
      3.请务必正确填写姓名和身份证号码，否则将无法提现<br/>
      4.<b><font color="red">保存信息后，我们将通过公安部核验身份证号码和姓名是否正确。公安部核验需要收费，我们提供免费核验1次。如果因为信息填写错误导致核验不通过，再核验一次收费5元，费用从个人推广奖励中扣除。身份证信息核验通过后不可修改</font></b><br/>
    </div>
      <div style="margin: 16px">
        <van-button round block type="info" @click="doSave">保存</van-button>
      </div>
    </van-form>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";

export default {
  data() {
    return {
      token: "",
      name: "",
      idcard: "",
      idcard_check_status: "",
      canUpdate: true,
    };
  },
  components: {
    CopyRight,
  },

  mounted:function(){
      this.loadData();
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },
    loadData(){
      let token = window.sessionStorage.getItem("token");
      axios
        .get("api/member/get_info?token="+token)
        .then((res) => {
          this.name = res.data.data.realname;
          this.idcard = res.data.data.idcard;
          if(res.data.data.idcard_check_status == 0){
            this.idcard_check_status = "待核验";
          }else if(res.data.data.idcard_check_status == 1){
            this.idcard_check_status = "待核通过";
          }else if(res.data.data.idcard_check_status == 2){
            this.idcard_check_status = "待核不通过";
          }
          
          if(res.data.data.status == 1){
            this.canUpdate = false;
          }
        })
        .catch((err) => {
          console.log("profile err-->" + err);
        });
    },

    doSave() {
      if(!this.canUpdate){
        this.$toast.fail("您已经通过个人信息认证，无法再修改");
        return;
      }

      if (!this.name) {
        this.$toast.fail("请填写姓名");
        return;
      }

      if (!this.idcard) {
        this.$toast.fail("请填写身份证号码");
        return;
      }

      let postData = this.$qs.stringify({
            realname:this.name,
            idcard:this.idcard,
            token:window.sessionStorage.getItem("token"),
        });

      axios
        .post("api/member/save_profile", postData)
        .then((res) => {
          if(res.data.code == 0){
            this.$toast("保存成功");
          }else{
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log("profile err-->" + err);
        });
    },
  },
};
</script>
